<template>
  <validation-observer v-if="value" v-slot="{ invalid }" tag="div">
    <v-card flat :loading="loading" outlined>
      <v-card-title class="pb-2">
        {{ $t('customer.orders.orderItems') }}
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <validation-provider
          rules="required"
          :name="$t('customer.orders.currency')"
          v-slot="{ errors }"
        >
          <cz-currency-picker
            v-model="currency"
            :label="$t('customer.orders.currency')"
            :items="currencyTypes"
            :error-messages="errors"
            :placeholder="$t('customer.orders.selectCurrency')"
            outlined
            dense
            style="max-width: 400px"
            :readonly="readOnlyMode"
          />
        </validation-provider>
        <v-form :disabled="loading" class="flex-grow-1">
          <cz-data-table
            hide-default-footer
            :no-data-text="$t('customer.orders.orderItemsNoData')"
            :columns="columns"
            :items="orderItems || []"
            class="table"
            :height="tableHeight"
          >
            <template #body.append>
              <div class="pa-4 mt-2" v-visible="canAddItem">
                <cz-button
                  color="info"
                  :icon-src="mdiPlusCircleOutline"
                  :title="$t('customer.orders.orderItemsPage.newItem')"
                  @click="onAddItem"
                  outlined
                  :disabled="!value.currency"
                />
              </div>
            </template>
            <template #footer>
              <amount-summary
                v-if="value.currency"
                :vat-amount="value.vatAmount"
                :net-amount="value.netAmount"
                :total-amount="value.totalAmount"
                :currency="value.currency"
                :vat-percent="vendorVatPercent"
              />
            </template>
            <template #item.itemCode="{ item, index, header }">
              <validation-provider
                rules="required"
                :name="header.text"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="item.catalogItemId"
                  dense
                  :readonly="!item.mode"
                  :items="catalogItems"
                  hide-details
                  :placeholder="$t('customer.orders.orderItemsPage.selectItem')"
                  item-text="itemCode"
                  :error-messages="errors"
                  item-value="_id"
                  @change="onItemChange($event, index)"
                  :clearable="!readOnlyMode"
                >
                  <template #item="{ item }">
                    <span>{{ `${item.itemCode} - ${item.itemName}` }}</span>
                  </template>
                </cz-autocomplete>
              </validation-provider>
            </template>
            <template #item.supplyDate="{ item, header }">
              <validation-provider
                v-if="item.catalogItemId"
                rules="required"
                :name="header.text"
                v-slot="{ errors }"
              >
                <cz-new-date-picker
                  v-model="item.supplyDate"
                  hide-details
                  :readonly="!item.mode"
                  dense
                  open-menu-on-click
                  :error-messages="errors"
                />
              </validation-provider>
            </template>
            <template #item.quantity="{ item, header }">
              <validation-provider
                v-if="item.catalogItemId"
                rules="required|min:1"
                :name="header.text"
              >
                <cz-input
                  v-model="item.quantity"
                  hide-details
                  :readonly="!item.mode"
                  dense
                  outlined
                  type="number"
                />
              </validation-provider>
            </template>
            <template #item.pricePerUnit="{ item, header }">
              <validation-provider
                v-if="item.catalogItemId"
                rules="required"
                :name="header.text"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="item.pricePerUnit"
                  :error-messages="errors"
                  dense
                  hide-details
                  type="number"
                  :readonly="
                    item.catalogItem.priceType ===
                      PriceType.WITH_PRICE_UNCHANGEABLE || !item.mode
                  "
                />
              </validation-provider>
            </template>
            <template #item.totalAmount="{ item }">
              <div v-if="item.quantity > 0">
                {{
                  formatAmountWithCurrency(
                    parseFloat(item.pricePerUnit) * parseInt(item.quantity),
                    value.currency
                  )
                }}
              </div>
            </template>
            <template #item.actions="{ item, index }" v-if="!readOnlyMode">
              <div
                v-if="!!item.mode"
                class="d-flex align-center"
                style="column-gap: 10px"
              >
                <cz-button
                  v-if="item.mode === 'create'"
                  :title="$t('common.add')"
                  color="info"
                  small
                  :icon-src="mdiPlus"
                  @click="onAddItemToOrder(item, index)"
                  :disabled="!canUpdateItem(item)"
                />
                <cz-button
                  v-else-if="item.mode === 'edit'"
                  :title="$t('common.update')"
                  color="info"
                  small
                  :icon-src="mdiCheck"
                  @click="onAddItemToOrder(item, index)"
                  :disabled="!canUpdateItem(item)"
                />
                <cz-button
                  color="negative"
                  :icon-src="mdiCloseCircleOutline"
                  text
                  :icon-size="20"
                  @click="onCancelItemUpdate(item, index)"
                />
              </div>
              <div v-else class="d-flex align-center">
                <cz-button
                  text
                  :icon-src="mdiPencil"
                  :icon-size="22"
                  color="info"
                  small
                  @click="onEditOrderItem(index)"
                />
                <cz-button
                  text
                  :icon-src="mdiTrashCanOutline"
                  :icon-size="22"
                  small
                  color="negative"
                  @click="onDeleteOrderItem(index)"
                />
              </div>
            </template>
          </cz-data-table>
        </v-form>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
import {
  CzDataTable,
  CzButton,
  CzInput,
  CzAutocomplete,
  CzNewDatePicker,
  CzCurrencyPicker
} from '@/components';
import AmountSummary from '@/domain/shared/components/AmountSummary.vue';
import {
  mdiPlusCircleOutline,
  mdiPlusOutline,
  mdiPlus,
  mdiCheck,
  mdiCancel,
  mdiCloseCircleOutline,
  mdiTrashCanOutline,
  mdiPencil
} from '@mdi/js';
import PriceType from '@/domain/customer/types/PriceType';
import currencyTypes from '@/shared/types/CurrencyTypes';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';

const cellClass = 'py-2';
export default {
  name: 'OrderItems',
  components: {
    CzNewDatePicker,
    CzDataTable,
    CzAutocomplete,
    CzCurrencyPicker,
    CzButton,
    CzInput,
    AmountSummary
  },
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    readOnlyMode: {
      type: Boolean
    },
    catalogItems: {
      type: Array,
      default: () => []
    },
    vendorVat: {
      type: Number
    },
    tableHeight: {
      type: [String, Number]
    }
  },
  computed: {
    currency: {
      set(value) {
        const _order = structuredClone(this.value);
        _order.currency = value;
        this.$emit('input', _order);
      },
      get() {
        return this.value.currency;
      }
    },
    // orderItems() {
    //   return this.value?.orderItems || [];
    // },
    vendorVatPercent() {
      if (this.vendorVat > 0) {
        return `${this.vendorVat * 100}%`;
      }
      return this.$t('customer.orders.noVat');
    },
    canAddItem() {
      return (
        !this.readOnlyMode &&
        this.value?.orderItems?.filter((item) => !!item.mode).length === 0
      );
    }
  },
  created() {
    if (!this.value?.orderItems) {
      const _order = structuredClone(this.value);
      _order.orderItems = [];
      this.$emit('input', _order);
      this.orderItems = [];
    } else {
      this.orderItems = structuredClone(this.value.orderItems);
    }
  },
  data() {
    return {
      PriceType,
      mdiCancel,
      mdiPlusOutline,
      currencyTypes,
      mdiPlusCircleOutline,
      mdiPlus,
      mdiCheck,
      mdiCloseCircleOutline,
      mdiTrashCanOutline,
      mdiPencil,
      editedOrderItems: {},
      orderItems: [],
      columns: [
        {
          text: this.$t('customer.orders.orderItemsPage.rowNumber'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'rowNumber',
          cellClass,
          sortable: false,
          width: 120
        },
        {
          text: this.$t('customer.orders.orderItemsPage.itemNumber'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'itemCode',
          cellClass,
          sortable: false,
          width: 200
        },
        {
          text: this.$t('customer.orders.orderItemsPage.itemName'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          cellClass,
          value: 'catalogItem.itemName',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('customer.orders.orderItemsPage.supplyDate'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'supplyDate',
          cellClass,
          sortable: false,
          width: 200
        },
        {
          text: this.$t('customer.orders.orderItemsPage.unitOfMeasure'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'catalogItem.itemUnitOfMeasureText',
          cellClass,
          sortable: false,
          width: 120
        },
        {
          text: this.$t('customer.orders.orderItemsPage.quantity'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'quantity',
          cellClass,
          sortable: false,
          width: 100
        },
        {
          text: this.$t('customer.orders.orderItemsPage.quantityReported'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'quantityReported',
          cellClass,
          sortable: false,
          width: 120
        },
        {
          text: this.$t('customer.orders.orderItemsPage.quantityLeft'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'quantityLeft',
          cellClass,
          sortable: false,
          width: 110
        },
        {
          text: this.$t('customer.orders.orderItemsPage.price'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'pricePerUnit',
          cellClass,
          sortable: false,
          width: 120
        },
        {
          text: this.$t('customer.orders.orderItemsPage.totalAmount'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'totalAmount',
          cellClass,
          width: 200,
          sortable: false
        },
        {
          text: '',
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'actions',
          cellClass,
          sortable: false
        }
      ]
    };
  },
  methods: {
    formatAmountWithCurrency,
    onAddItem() {
      let rowNumber = 10;

      if (this.orderItems.length) {
        // if order already contain item, then we need to get the row with the max rowNumber
        // and add 10 to it
        this.orderItems.forEach((item) => {
          if (item.rowNumber > rowNumber) {
            rowNumber = item.rowNumber;
          }
        });

        rowNumber = rowNumber + 10;
      }

      this.orderItems.push({
        rowNumber,
        catalogItemId: '',
        itemCode: '',
        itemName: '',
        supplyDate: this.value.issueDate,
        itemUnitOfMeasure: undefined,
        quantity: undefined,
        itemPrice: undefined,
        totalAmount: undefined,
        pricePerUnit: undefined,
        mode: 'create'
      });

      // this.$emit('input', _order);
    },

    canUpdateItem(item) {
      return (
        item.catalogItemId &&
        item.supplyDate &&
        item.quantity &&
        item.pricePerUnit
      );
    },

    onAddItemToOrder(item, index) {
      item.mode = '';
      const _order = structuredClone(this.value);
      _order.orderItems = structuredClone(this.orderItems);
      this.$emit('input', _order);
    },

    onItemChange(value, index) {
      // extract full catalog item from list
      const catalogItem = this.catalogItems.find(
        (cItem) => cItem._id === value
      );
      this.orderItems[index].catalogItemId = value;
      if (catalogItem) {
        this.orderItems[index].pricePerUnit = catalogItem.itemPrice;
        this.orderItems[index].catalogItem = catalogItem;
      } else {
        this.orderItems[index].itemName = '';
        this.orderItems[index].catalogItem = null;
        this.orderItems[index].supplyDate = null;
        this.orderItems[index].quantity = undefined;
        this.orderItems[index].itemPrice = undefined;
        this.orderItems[index].totalAmount = undefined;
        this.orderItems[index].netAmount = undefined;
        this.orderItems[index].vatAmount = undefined;
        this.orderItems[index].catalogItem = null;
        this.orderItems[index].itemUnitOfMeasure = undefined;
      }
    },
    onItemFieldChange(value, index, fieldName) {
      const _order = structuredClone(this.value);
      _order.orderItems[index][fieldName] = value;
      // this.$emit('input', _order);
    },
    onDeleteOrderItem(index) {
      this.orderItems.splice(index, 1);
      if (this.value.orderItems[index]) {
        const _order = structuredClone(this.value);
        _order.orderItems.splice(index, 1);
        this.$emit('input', _order);
      }
    },
    onEditOrderItem(index) {
      const _orderItems = structuredClone(this.orderItems);
      // copy item before edit
      this.editedOrderItems[index] = structuredClone(_orderItems[index]);
      _orderItems[index].mode = 'edit';
      this.orderItems = _orderItems;
    },
    onCancelItemUpdate(item, index) {
      if (item.mode === 'create') {
        this.onDeleteOrderItem(index);
      } else if (item.mode === 'edit') {
        item.mode = '';
        // take original item before change
        const editedItem = this.editedOrderItems[index];
        editedItem.mode = '';
        const _orderItems = structuredClone(this.orderItems);
        _orderItems[index] = structuredClone(editedItem);
        this.orderItems = _orderItems;
      }
    },
    calculateOrderTotalAmount() {
      const _order = structuredClone(this.value);
      if (!this.value?.orderItems?.length) {
        _order.totalAmount = 0;
        _order.netAmount = 0;
        _order.vatAmount = 0;
      }
      _order.netAmount = 0;
      _order.orderItems?.forEach((item) => {
        if (!item.mode && item.quantity && parseInt(item.quantity) > 0) {
          const netAmount =
            parseFloat(item.quantity) * parseFloat(item.pricePerUnit);
          _order.netAmount += netAmount;
        }
      });
      _order.vatAmount = parseFloat(_order.netAmount) * this.vendorVat;
      _order.totalAmount = _order.netAmount + _order.vatAmount;
      this.$emit('input', _order);
    }
  },
  watch: {
    vendorVat: {
      handler() {
        if (!this.readOnlyMode) {
          this.calculateOrderTotalAmount();
        }
      }
    },
    orderItems: {
      deep: true,
      handler() {
        if (!this.readOnlyMode) {
          this.calculateOrderTotalAmount();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
::v-deep .table .v-table tbody td {
  border: none !important;
}
// ::v-deep .table .v-table tbody tr:not(:last-child) {
//   border-bottom: none !important;
// }
</style>
